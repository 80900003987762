<template>
  <div>
    <div>
      <div
        class="row justify-content-between align-items-center w-100 my-4 mx-6"
      >
        <h4 class="">Fiili Kullanım Nitelikleri</h4>
        <button class="btn btn-primary mx-6" @click="addFileModal = true">
          <p style="font-size: 13px" class="text-white d-flex align-items-center">
            <i class="flaticon2-plus mb-1 mr-1 "></i> Yeni Nitelik Ekle
          </p>
        </button>
      </div>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <hr class="w-100" />
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Fiili Kullanım Nitelikleri Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end w-100">
            <button @click="updatePurpose(item)">
              <i class="flaticon-eye icon-lg"></i>
            </button>
            <button class="border-0" @click="openDeleteHandler(item)">
              <i class="flaticon2-trash text-dark text-md ml-4"></i>
            </button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4>Fiili Kullanım Niteliği Bilgiler</h4>
      </template>
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label for="">Adı</label>
            <input type="text" class="form-control" v-model="nitelik" />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="removeModal">
            Vazgeç
          </b-button>
          <b-button variant="success" class="ml-4" @click="addPurpose"> Kaydet </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deletePurpose"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PURPOSE } from "@/core/services/store/usage.module.js";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "name" },
        { text: "İşlemler ", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      totalItems:0,
      nitelik : "",
      deleteOperationUrl: '',
      deleteIds: '',
      updateModal:0,
      purpose_id: ""

    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Fiili Kullanım Nitelikleri", route: "/sistem-kullanicilari" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.$store.dispatch(PURPOSE, this.myUser).then((Response) => {
        this.items = Response;
        this.totalItems = Response.length;
        this.pagination = {
            current_page: 1,
            total_pages: Math.ceil( Response.length / 10) ,
            total_items: Response.length,
          };
      this.loading = false;
      });


      

    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async addPurpose(){
      const data = {
        user_token: this.myUser,
        name: this.nitelik,
        category:2
      };
      if(!this.updateModal){
        await this.$ApiService
        .post("admin/purposeofusage", data)
        .then((res) => {
          if (res.data.status.code === 200) {
            this.getList()
            this.nitelik = ""
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            )
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }else{
        data["purposeofusage_id"] = this.purpose_id
        const formData = new FormData()
        for (const key in data) {
          formData.append(key, data[key]);
      }
        await this.$ApiService
        .put("admin/purposeofusage", formData)
        .then((res) => {
          if (res.data.status.code === 200) {
            this.getList()
            this.nitelik = ""
            this.updateModal = false
            this.purpose_id = ""
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            )
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }
      
    

    },
    updatePurpose(item){
      this.nitelik = item.name
      this.updateModal = true
      this.addFileModal = true
      this.purpose_id = item._id.$oid
    },
    removeModal(){
      this.updateModal = false
      this.addFileModal = false
      this.nitelik = ""
      this.purpose_id = ""
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `admin/purposeofusage`;
      this.deleteIds = `?user_token=${this.myUser}&purposeofusage_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeletePurpose');
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
